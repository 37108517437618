import { RootState } from '@store';
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useSelector } from 'react-redux';

import { Menu } from 'antd';

import navigation from '../../../../navigation/vertical';

const { SubMenu } = Menu;

export interface MenuInfo {
  key: string;
  keyPath: string[];
  /** @deprecated This will not support in future. You should avoid to use this */
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

type MenuItemProps = {
  onClose: (info: MenuInfo) => void;
};

export default function MenuItem(props: MenuItemProps) {
  const { onClose } = props;
  const router = useRouter();

  // Redux
  const customise = useSelector((state: RootState) => state.Customize);

  // Location
  const { pathname } = router;

  const splitLocation = pathname.split('/');

  // Menu
  const splitLocationUrl = splitLocation[splitLocation.length - 2] + '/' + splitLocation[splitLocation.length - 1];

  const menuItem = navigation.map((item: any, index: React.Key) => {
    if (item.header) {
      return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
    }

    if (item.children) {
      return (
        <SubMenu key={item.id} icon={item.icon} title={item.title}>
          {item.children.map((childrens: any, index: React.Key) => {
            if (!childrens.children) {
              const childrenNavLink = childrens.navLink.split('/');

              return (
                // Level 2
                <Menu.Item
                  key={childrens.id}
                  className={
                    splitLocationUrl ===
                    childrenNavLink[childrenNavLink.length - 2] + '/' + childrenNavLink[childrenNavLink.length - 1]
                      ? 'ant-menu-item-selected'
                      : 'ant-menu-item-selected-in-active'
                  }
                  onClick={onClose}
                >
                  <Link href={childrens.navLink}>
                    <a>{childrens.title}</a>
                  </Link>
                </Menu.Item>
              );
            } else {
              return (
                // Level 3
                <SubMenu key={childrens.id} title={childrens.title}>
                  {childrens.children.map((childItem: any, index: React.Key) => {
                    const childrenItemLink = childItem.navLink.split('/');

                    return (
                      <Menu.Item
                        key={childItem.id}
                        className={
                          splitLocationUrl ===
                          childrenItemLink[childrenItemLink.length - 2] +
                            '/' +
                            childrenItemLink[childrenItemLink.length - 1]
                            ? 'ant-menu-item-selected'
                            : 'ant-menu-item-selected-in-active'
                        }
                        onClick={onClose}
                      >
                        <Link href={childItem.navLink}>
                          <a>{childItem.title}</a>
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </SubMenu>
      );
    } else {
      const itemNavLink = item.navLink?.split('/') ?? [];

      return (
        // Level 1
        <Menu.Item
          key={item.id}
          icon={item.icon}
          onClick={onClose}
          className={
            splitLocation[splitLocation.length - 2] + '/' + splitLocation[splitLocation.length - 1] ===
            itemNavLink[itemNavLink.length - 2] + '/' + itemNavLink[itemNavLink.length - 1]
              ? 'ant-menu-item-selected'
              : 'ant-menu-item-selected-in-active'
          }
        >
          <Link href={item?.navLink ?? '#'}>
            <a>{item.title}</a>
          </Link>
        </Menu.Item>
      );
    }
  });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : '',
        splitLocation[splitLocation.length - 2],
      ]}
      theme={customise.theme == 'light' ? 'light' : 'dark'}
    >
      {menuItem}
    </Menu>
  );
}
