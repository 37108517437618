import { RootState } from '@store';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from '@store';
import { redirectToLogin } from '@helpers/router';
import { Spin, Button, Menu, Dropdown, Col, Avatar } from 'antd';
import { User, People, InfoSquare, Calendar, Discount, Logout } from 'react-iconly';

export default function HeaderUser() {
  const customise = useSelector((rootState: RootState) => rootState.Customize);
  const logouting = useSelector((rootState: RootState) => rootState.loading.effects.Session.makeLogout);
  const dispatch = useDispatch<Dispatch>();

  const makeLogout = async () => {
    await dispatch.Session.makeLogout();
    redirectToLogin({ back: false });
  };

  const menu = (
    <Menu theme={customise.theme == 'light' ? 'light' : 'dark'}>
      <Menu.Item
        key={0}
        icon={
          <span className="remix-icon da-vertical-align-middle da-text-color-dark-0">
            <User size={16} />
          </span>
        }
        className="da-text-color-dark-0"
      >
        <Link href="/profile/personal-information">
          <a>Meu Perfil</a>
        </Link>
      </Menu.Item>

      <Menu.Item
        key={5}
        icon={
          <span className="remix-icon da-vertical-align-middle da-text-color-dark-0">
            <Logout size={16} />
          </span>
        }
        className="da-text-color-dark-0"
      >
        <Button type="link" onClick={makeLogout} loading={logouting}>
          <a>Logout</a>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Col className="da-d-flex-center" onClick={(e) => e.preventDefault()}>
          <Spin spinning={logouting}>
            <Avatar src={'/vendor-theme/images/memoji/memoji-1.png'} size={40} />
          </Spin>
        </Col>
      </Dropdown>
    </Col>
  );
}
