import { RootState } from '@store';
import React, { useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Layout, Button, Row, Col, Input } from 'antd';
import { RiCloseLine, RiMenuFill } from 'react-icons/ri';
import { Search } from 'react-iconly';

import HeaderSearch from './HeaderSearch';
import HeaderUser from './HeaderUser';
import HeaderNotifications from './HeaderNotifications';
import HeaderText from './HeaderText';

const { Header } = Layout;

type MenuHeader = {
  setVisible: (e: boolean) => void;
};

export default function MenuHeader(props: MenuHeader) {
  const { setVisible } = props;

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  // Redux
  const customise = useSelector((state: RootState) => state.Customize);

  // Focus
  const inputFocusRef = useRef<Input | null>(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true);

    setTimeout(() => {
      if (inputFocusRef.current) {
        inputFocusRef.current.focus({
          cursor: 'start',
        });
      }
    }, 200);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
    setSearchHeader(false);
  };

  // Children
  const headerChildren = () => {
    return (
      <Row className="da-w-100 da-position-relative" align="middle" justify="space-between">
        <Col className="da-mobile-sidebar-button da-mr-24">
          <Button
            className="da-mobile-sidebar-button"
            type="text"
            onClick={showDrawer}
            icon={<RiMenuFill size={24} className="remix-icon da-text-color-black-80 da-text-color-dark-30" />}
          />
        </Col>

        <Col
          flex="1"
          style={{ display: !searchHeader ? 'none' : 'block' }}
          className={`da-pr-md-0 da-pr-16 da-header-search ${searchActive && 'da-header-search-active'}`}
        >
          <HeaderSearch inputFocusProp={inputFocusProp} setSearchHeader={setSearchHeader} />
        </Col>

        {!searchHeader && <div />}

        <Col>
          <Row align="middle">
            <Col className="da-d-flex-center da-mr-4">
              {!searchHeader ? (
                <Button
                  type="text"
                  icon={
                    <span className="da-text-color-black-60">
                      <Search />
                    </span>
                  }
                  onClick={() => searchClick()}
                />
              ) : (
                <Button
                  type="text"
                  icon={
                    <span className="da-text-color-black-60">
                      <RiCloseLine size={24} />
                    </span>
                  }
                  onClick={() => setSearchHeader(false)}
                />
              )}
            </Col>

            <HeaderNotifications />

            <HeaderUser />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Header>
      <Row justify="center" className="da-w-100">
        {customise.contentWidth == 'full' && <Col span={24}>{headerChildren()}</Col>}

        {customise.contentWidth == 'boxed' && (
          <Col xxl={20} xl={22} span={24}>
            {headerChildren()}
          </Col>
        )}
      </Row>
    </Header>
  );
}
