import React, { useEffect } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import Link from 'next/link';
import Image from 'next/image';

const Loading = (): JSX.Element => {
  return (
    <Row className="da-bg-color-primary-4 da-bg-color-dark-90 da-text-center">
      <Col className="da-error-content da-py-32" span={24}>
        <Row className="da-h-100" align="middle" justify="center">
          <Col>
            <div className="da-position-relative da-mt-sm-0 da-mt-64 da-mb-32">
              <div className="da-error-content-circle da-bg-dark-100"></div>
              <Image
                className="da-position-relative da-d-block da-m-auto"
                src={'/vendor-theme/images/logo/logo.png'}
                width={200}
                height={200}
                alt="Logo dark"
              />
            </div>

            <h1 className="da-error-content-title da-mb-sm-0 da-mb-8 da-font-weight-300">
              Carregando página <Spin />
            </h1>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="da-py-24">
        <p className="da-mb-0 da-badge-text">COPYRIGHT ©2020 Hypeople, All rights Reserved</p>
      </Col>
    </Row>
  );
};

export default Loading;
