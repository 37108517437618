import React from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { Divider, Avatar, Row, Col, Typography } from 'antd';
import { RiSettings3Line } from 'react-icons/ri';

const { Paragraph } = Typography;

type MenuFooterProps = {
  collapsed: boolean;
  onClose: React.MouseEventHandler;
};

export default function MenuFooter(props: MenuFooterProps) {
  const AuthenticatedUser = useSelector((state: RootState) => state.Session.user);

  if (!AuthenticatedUser?.id) {
    return null;
  }

  return props.collapsed === false ? (
    <Row className="da-sidebar-footer da-pb-24 da-px-24 da-bg-color-dark-100" align="middle" justify="space-between">
      <Divider className="da-border-color-black-20 da-border-color-dark-70 da-mt-0" />

      <Col
        style={{
          flex: 7,
        }}
      >
        <Row align="middle">
          <Avatar size={36} src={'/vendor-theme/images/memoji/memoji-1.png'} className="da-mr-8" />

          <div
            style={{
              flex: 1
            }}
          >
            <Paragraph
              className="da-m-0 da-d-block da-text-color-black-100 da-text-color-dark-0 da-p1-body"
              style={{ maxWidth: '100%' }}
              ellipsis={{ expandable: true, symbol: '+', suffix: '' }}
            >
              {AuthenticatedUser.name || ''}
            </Paragraph>

            <Link href="/profile/personal-information">
              <a className="da-badge-text da-text-color-dark-30" onClick={props.onClose}>
                Ver perfil
              </a>
            </Link>
          </div>
        </Row>
      </Col>

      <Col
        style={{
          flex: 1
        }}
      >
        <Link href="/profile/personal-information">
          <a onClick={props.onClose}>
            <RiSettings3Line className="remix-icon da-text-color-black-100 da-text-color-dark-0" size={24} />
          </a>
        </Link>
      </Col>
    </Row>
  ) : (
    <Row className="da-sidebar-footer da-pt-16 da-mb-16 da-bg-color-dark-100" align="middle" justify="center">
      <Col>
        <Link href="/profile/personal-information">
          <a onClick={props.onClose}>
            <Avatar size={36} src={'/vendor-theme/images/memoji/memoji-1.png'} />
          </a>
        </Link>
      </Col>
    </Row>
  );
}
