import Link from 'next/link';
import Image from 'next/image';
import { RootState } from '@store';
import { useSelector } from 'react-redux';

import Yoda from '@public/vendor-theme/images/logo/logo.png';
import YodaDark from '@public/vendor-theme/images/logo/logo.png';
import YodaRtl from '@public/vendor-theme/images/logo/logo.png';
import YodaRtlDark from '@public/vendor-theme/images/logo/logo.png';

type MenuLogoProps = {
  onClose: (e: React.SyntheticEvent) => void;
};

export default function MenuLogo(props: MenuLogoProps) {
  const themeConfig = useSelector((state: RootState) => state.App);
  const customise = useSelector((state: RootState) => state.Customize);

  return (
    <Link href="/dashboard">
      <a className="da-header-logo da-d-flex da-align-items-end" onClick={props.onClose}>
        {customise.direction == 'rtl' ? (
          customise.theme == 'light' ? (
            <Image src={YodaRtl} alt="Logo" className="da-logo" />
          ) : (
            <Image src={YodaRtlDark} alt="Logo" className="da-logo" />
          )
        ) : customise.theme == 'light' ? (
          <Image src={Yoda} alt="Logo" className="da-logo" />
        ) : (
          <Image src={YodaDark} alt="Logo" className="da-logo" />
        )}

        <span className="h3 d-font-weight-800 da-text-color-primary-1 da-mb-6"></span>

        <span
          className="da-p1-body da-font-weight-500 da-text-color-black-40 da-mb-16 da-ml-4"
          style={{
            letterSpacing: -1.5,
          }}
        >
          v.{themeConfig.version}
        </span>
      </a>
    </Link>
  );
}
