import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { Col, Layout, Row } from 'antd';
const { Footer } = Layout;

export default function MenuFooter() {
  const themeConfig = useSelector((state: RootState) => state.App);
  return (
    <Footer className="da-bg-color-black-10 da-bg-color-dark-100">
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <p className="da-badge-text da-mb-0 da-text-color-dark-30">COPYRIGHT ©2021, All rights Reserved</p>
        </Col>

        <Col md={12} span={24} className="da-mt-sm-8 da-text-sm-center da-text-right">
          <a href="#" className="da-badge-text da-text-color-dark-30">
            🥁 Version: {themeConfig.version}
          </a>
        </Col>
      </Row>
    </Footer>
  );
}
