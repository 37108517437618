import { RootState } from '@store';
import React, { useState, createElement, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

import { Layout, Button, Row, Col } from 'antd';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri';

import logoSmall from '@public/vendor-theme/images/logo/logo.png';

import MenuLogo from './logo';
import MenuFooter from './footer';
// import MenuItem from './item';
import MenuMobile from './mobile';

const MenuItem = dynamic(() => import('./item'), { ssr: false });

const { Sider } = Layout;

type SidebarProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export default function Sidebar(props: SidebarProps) {
  const { visible, setVisible } = props;
  const router = useRouter();

  // Redux
  const customise = useSelector((state: RootState) => state.Customize);

  // Collapsed
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (customise.sidebarCollapsed) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [customise]);

  // Location
  const { pathname } = router;

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
  };

  // Menu
  function toggle() {
    setCollapsed(!collapsed);
  }

  const trigger = createElement(collapsed ? RiMenuUnfoldLine : RiMenuFoldLine, {
    className: 'trigger',
    onClick: toggle,
  });

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={256}
      className="da-sidebar da-bg-color-black-0 da-bg-color-dark-100"
    >
      <Row className="da-mr-12 da-ml-24 da-mt-24" align="bottom" justify="space-between">
        <Col>{collapsed === false ? <MenuLogo onClose={onClose} /> : ''}</Col>

        {customise.sidebarCollapseButton && (
          <Col className="da-pr-0">
            <Button icon={trigger} type="text" className="da-float-right da-text-color-dark-0"></Button>
          </Col>
        )}

        {collapsed !== false && (
          <Col className="da-mt-8">
            <Link href="/">
              <a onClick={onClose}>
                <Image className="da-logo" src={logoSmall} alt="logo" />
              </a>
            </Link>
          </Col>
        )}
      </Row>

      <MenuItem onClose={onClose} />

      <MenuFooter onClose={onClose} collapsed={collapsed} />

      <MenuMobile onClose={onClose} visible={visible} />
    </Sider>
  );
}
