import React from 'react';

import { Drawer } from 'antd';
import { RiCloseFill } from 'react-icons/ri';

import MenuLogo from '../logo';
import MenuItem, { MenuInfo } from '../item';
import MenuFooter from '../footer';

type MenuMobileProps = {
  onClose: any;
  visible: boolean;
};

export default function MenuMobile(props: MenuMobileProps) {
  const { onClose, visible } = props;

  return (
    <Drawer
      title={<MenuLogo onClose={onClose} />}
      className="da-mobile-sidebar"
      placement="left"
      closable={true}
      onClose={onClose}
      visible={visible}
      closeIcon={<RiCloseFill className="remix-icon da-text-color-black-80" size={24} />}
    >
      <MenuItem onClose={onClose} />

      <MenuFooter onClose={onClose} collapsed={false} />
    </Drawer>
  );
}
