import React, { useState, useRef, useMemo } from 'react';
import Link from 'next/link';

import { AutocompleteState, createAutocomplete } from '@algolia/autocomplete-core';

import { Input } from 'antd';
import { RiSearchLine } from 'react-icons/ri';

import pagesJson from './search-data/algolia-pages.json';

type HeaderSearchProps = {
  setSearchHeader: (e: boolean) => void;
  inputFocusProp: any;
};

export default function HeaderSearch(props: HeaderSearchProps) {
  const [autocompleteState, setAutocompleteState] = useState<any>({});

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        // @ts-ignore
        getSources() {
          return [
            {
              sourceId: 'pages-source',
              getItemInputValue({ item }) {
                return item.query;
              },
              getItems({ query }) {
                if (!query) {
                  return pagesJson;
                }
                return pagesJson.filter((item) => item.title.toLowerCase().includes(query.toLowerCase()));
              },
              getItemUrl({ item }) {
                return item.url;
              },
              templates: {
                item({ item }: any) {
                  return item.title;
                },
              },
            },
          ];
        },
      }),
    [],
  );

  function linkHandleClick() {
    autocompleteState.query = '';
    props.setSearchHeader(false);
  }

  return (
    <div {...autocomplete.getRootProps({})}>
      <Input
        placeholder="Pesquisar..."
        prefix={
          <span className="site-form-item-icon da-text-color-black-80 da-text-color-dark-20">
            <RiSearchLine />
          </span>
        }
      />

      <div className="da-header-search-result">
        {autocompleteState.isOpen &&
          autocompleteState.collections.map((collection: any, index: number) => {
            const { source, items } = collection;

            return (
              items.length > 0 && (
                <ul key={index} {...autocomplete.getListProps()}>
                  {items.map(
                    (item2: any, index2: number) =>
                      index < 4 && (
                        <li key={index2} className="da-font-weight-500">
                          <Link href={item2.url}>
                            <a onClick={linkHandleClick}>{item2.title}</a>
                          </Link>
                        </li>
                      ),
                  )}
                </ul>
              )
            );
          })}
      </div>
    </div>
  );
}
