import { Home, Login, Logout, Wallet, People } from 'react-iconly';

const pages = [
  {
    id: 'stock',
    title: 'Estoque',
    icon: (
      <span className="remix-icon">
        <Home />
      </span>
    ),
    children: [
      {
        id: 'products',
        title: 'Produtos',
        navLink: '/estoque',
        icon: (
          <span className="remix-icon">
            <Wallet />
          </span>
        ),
      },
      {
        id: 'inputs',
        title: 'Entradas',
        navLink: '/estoque/entradas',
        icon: (
          <span className="remix-icon">
            <Login />
          </span>
        ),
      },
      {
        id: 'outputs',
        title: 'Saídas',
        navLink: '/estoque/saidas',
        icon: (
          <span className="remix-icon">
            <Logout />
          </span>
        ),
      },
    ],
  },
  {
    id: 'business',
    title: 'Empresas',
    navLink: '/empresa',
    icon: (
      <span className="remix-icon">
        <People />
      </span>
    ),
  },
];

export default pages;
