import { RootState, Dispatch } from '@store';
import { InitStatusType } from '@models/Session/types.d';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Row, Col, Spin } from 'antd';
import Sidebar from './fragments/menu/Sidebar';
import MenuHeader from './fragments/header';
import MenuFooter from './fragments/footer';
import ScrollTop from './fragments/scroll-to-top';
import Loading from '@components/layout/fragments/loading';
import { LoadingOutlined } from '@ant-design/icons';
import Head from 'next/head';

const { Content } = Layout;

type VerticalLayoutProps = {
  children: React.ReactNode;
  loadSession?: boolean;
  getUserData?: boolean;
  reloadSession?: boolean;
  title?: string;
};

export default function VerticalLayout({
  children,
  loadSession = true,
  getUserData = true,
  reloadSession = false,
  title,
}: VerticalLayoutProps) {
  const initStatus = useSelector((state: RootState) => state?.Session?.initStatus);
  const SessionStatus = useSelector((state: RootState) => state?.Session?.status);
  const dispatch = useDispatch<Dispatch>();
  const [visible, setVisible] = useState(false);
  const customise = useSelector((state: RootState) => state.Customize);

  useEffect(() => {
    if (loadSession && initStatus === InitStatusType.initializing) {
      dispatch.Session.getStatus({ getUserData });
    }
  }, [initStatus, loadSession]);

  useEffect(() => {
    if (reloadSession && initStatus !== InitStatusType.initializing) {
      dispatch.Session.getStatus({ getUserData });
    }
  }, []);

  if (
    (loadSession && initStatus === InitStatusType.initializing) ||
    (reloadSession && initStatus === InitStatusType.loading)
  ) {
    return <Loading />;
  }

  const requiredAutentication = React.isValidElement(children) ? children?.props?.requiredAutentication : false;

  return (
    <Layout className="da-app-layout">
      <Head>
        <title>{[title, process.env.NEXT_PUBLIC_APP_NAME].filter(Boolean).join(' | ')}</title>
      </Head>
      {(!requiredAutentication || (requiredAutentication && SessionStatus?.id)) && (
        <Sidebar visible={visible} setVisible={setVisible} />
      )}

      <Layout className="da-bg-color-dark-90">
        {(!requiredAutentication || (requiredAutentication && SessionStatus?.id)) && (
          <MenuHeader setVisible={setVisible} />
        )}

        <Spin indicator={<LoadingOutlined />} spinning={false}>
          <Content className="da-content-main">
            <Row justify="center">
              {customise.contentWidth == 'full' && <Col span={24}>{children}</Col>}

              {customise.contentWidth == 'boxed' && (
                <Col xxl={20} xl={22} span={24}>
                  {children}
                </Col>
              )}
            </Row>
          </Content>
        </Spin>

        {(!requiredAutentication || (requiredAutentication && SessionStatus?.id)) && <MenuFooter />}
      </Layout>

      <ScrollTop />
    </Layout>
  );
}
